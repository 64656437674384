.post-content {
  line-height: 1.4;
  @apply text-neutral-900 dark:text-neutral-200 text-base leading-normal;
}

.post-content code.hljs {
  border-radius: 0.25rem;
}

.post-content img {
  margin-inline: auto;
  display: block;
  max-width: 100%;
  height: auto;
  border-radius: 0.5rem;
  box-shadow: 0 0 1px 5px hsla(0deg, 0%, 0%, 0.05);
  margin-block: 2rem;
}

.post-content a {
  @apply text-blue-500 dark:text-blue-400 hover:text-blue-600 dark:hover:text-blue-500;
  text-decoration: none;
}

.post-content blockquote {
  margin-block: 1.5rem;
  padding: 1rem 1.5rem;
  /* background-color: hsla(var(--color-brandHsl), 0.05); */
  @apply bg-blue-200/50 dark:bg-blue-900/50 border-l-4 border-blue-500 dark:border-blue-400;
  border-radius: 0.25rem;
}

.post-content h2 {
  margin-block-start: 2em;
  margin-block-end: 0.5em;
  text-wrap: balance;
  @apply text-xl font-bold border-l-8 pl-3 -ml-5 leading-9 py-1;
}

.post-content h3 {
  margin-block-start: 1em;
  margin-block-end: 0.5em;
  text-wrap: balance;
  @apply text-lg font-semibold text-foreground dark:text-background leading-8;
}

.post-content h2:first-child,
.post-content h3:first-child {
  margin-block-start: 0;
}

.post-content p {
  margin-block-start: 1em;
  margin-block-end: 1em;
}

.post-content pre:has(code) {
  display: grid;
}

.post-content ul {
  margin-block-start: 1em;
  margin-block-end: 1em;
  padding-inline-start: 1rem;
  list-style-type: disc;
}

.post-header {
  text-align: center;
}
