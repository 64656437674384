body:not([class*="disableAnimation"]) .Input,
body:not([class*="disableAnimation"]) .Button {
  transition: 100ms cubic-bezier(0.075, 0.82, 0.165, 1) background-color,
    100ms cubic-bezier(0.075, 0.82, 0.165, 1) color,
    100ms cubic-bezier(0.075, 0.82, 0.165, 1) border-color;
}

.Input {
  position: relative;
  border: 1px solid var(--color-uiAccent);
  background-color: var(--color-input);
  color: var(--color-uiAccent);
}

.Input input,
.Input textarea {
  appearance: none;
  border: none;
  background-color: transparent;
  color: var(--color-foreground);
  width: 100%;
  resize: none;
}

.Input input:focus,
textarea:focus {
  outline: none;
}

.InputText {
  font-size: 16px !important;
}

.Input:focus-within {
  outline: none;
}

.InputSpinner {
  position: absolute;
  top: 50%;
  right: 10px;
  transform: translateY(-50%);
}

.Input:focus-within {
  background-color: var(--color-nodeHover);
  border-color: var(--color-edgeHover);
  box-shadow: none;
}

.ButtonType {
  color: inherit;
}

.CloseButton {
  position: absolute;
}

.CloseButton svg line {
  stroke-width: 10;
  stroke: var(--color-edgeHover);
  transition: 100ms stroke ease;
}

.CloseButton:hover svg line {
  stroke: var(--color-foreground);
}

.CancelNotice[data-style="warning"] {
  border: 1px solid var(--palette-yellow-2);
  background-color: var(--palette-yellow-2);
}

.CancelNotice[data-style="info"] {
  border: 1px solid var(--palette-purple-3);
  background-color: var(--palette-purple-3);
}

button.CancelNotice[data-style="info"]:hover {
  color: var(--color-highlightColor);
}

.Dialog {
  box-shadow: rgba(50, 50, 93, 0.25) 0px 13px 27px -5px,
    rgba(0, 0, 0, 0.3) 0px 8px 16px -8px;
}
