.iconButton {
  font-size: 0;
}

.twitter {
  --icon-size: 22px;
  width: var(--icon-size);
  height: var(--icon-size);
}
.twitter path {
  fill: #1da1f2;
}

@media (min-width: 800px) {
  .twitter {
    --icon-size: 24px;
  }
}

.GroupButton[aria-pressed="true"],
.GroupButton:disabled {
  cursor: default;
  z-index: -1;
}

.GroupButton:hover,
.GroupButton:focus {
  z-index: 1;
}

.GroupButton + .GroupButton {
  margin-left: -1px;
}

.LowerLinks a:focus {
  outline: none;
  color: var(--color-buttonFocus);
}
