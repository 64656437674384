:root {
  --rad: 3px;
}

.shared-header__logo {
  font-size: 0;
}

/* .shared-header__logo svg path {
  fill: var(--color-background);
} */

.shared-header-btn__icon {
  font-size: 0;
}

[data-radix-menu-content] {
  z-index: 100;
  position: relative;
  border-radius: var(--rad);
  list-style: none;
  display: grid;
}

@media (min-width: 800px) {
  .mobile-only {
    display: none;
  }

  .mobile-header__toggle,
  .mobile-header {
    display: none;
  }

  /* Hide Icons */
  .shared-header-btn__icon {
    display: none;
  }
}

@media (min-width: 900px) {
  /* Show Icons */
  .shared-header-btn__icon {
    display: inline-block;
  }
}

/* Mobile Header */

.mobile-header__toggle {
  position: fixed;
  top: 0;
  right: 0;
  background-color: hsla(var(--color-overlayColor), 0);
  padding: 10px;
  font-size: 0;
  z-index: 1;
}

.mobile-header__toggle svg path {
  fill: var(--color-foreground);
}

.mobile-header__overlay {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: hsla(var(--color-overlayColor), 0.5);
  opacity: 0.5;
  z-index: 100;
}

.mobile-header__content {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  width: 300px;
  max-width: calc(100% - 2rem);
  background-color: var(--color-background);
  z-index: 100;
  padding: 10px;
  padding-top: 50px;
  overflow-y: auto;
}
